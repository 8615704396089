import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { collection, getDocs, getFirestore, deleteDoc, doc } from 'firebase/firestore';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import firebaseApp from '../utils/firebaseConfig';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'eventTitle', label: 'Event Title', alignRight: false },
  { id: 'numberOfTickers', label: 'Number of Tickers', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      const name = _user.title;
      return (name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const db = getFirestore(firebaseApp);

export default function WinnersReportPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [winners, setWinners] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setUsers(usersData);

      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchRequests = async () => {
      try {
        const groupsRef = collection(db, 'requests');
        const querySnapshot = await getDocs(groupsRef);
        const fetchedRequests = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(group => group.status === 'Accepted');

        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        const usersMap = {};
        usersData.forEach(user => {
          usersMap[user.userId] = user;
        });

        const groupsWithFullName = fetchedRequests.map(group => {
          const user = usersMap[group.userId];
          const fullNameData = user ? `${user.firstName} ${user.lastName}` : '';
          const emailData = user ? user.email : '';
          const companyData = user ? user.company : '';
          const phoneNumberData = user ? user.phoneNumber : '';
          return { ...group, fullNameData, emailData, companyData, phoneNumberData };
        });

        setWinners(groupsWithFullName);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    const fetchPreviousRequests = async () => {
      try {
        const groupsRef = collection(db, 'previous_requests');
        const querySnapshot = await getDocs(groupsRef);
        const fetchedRequests = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(group => group.status === 'Accepted');

        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        const usersMap = {};
        usersData.forEach(user => {
          usersMap[user.userId] = user;
        });

        const groupsWithFullName = fetchedRequests.map(group => {
          const user = usersMap[group.userId];
          const fullNameData = user ? `${user.firstName} ${user.lastName}` : '';
          const emailData = user ? user.email : '';
          const companyData = user ? user.company : '';
          const phoneNumberData = user ? user.phoneNumber : '';
          return { ...group, fullNameData, emailData, companyData, phoneNumberData };
        });

        setWinners(prev => [...prev, ...groupsWithFullName]);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    

    
    fetchRequests()
    fetchPreviousRequests()
    fetchUsers();

  }, [users]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };


  const downloadWinnerCSV = () => {
    // Define CSV header
    const csvHeader = 'Full Name,Email,Company,Phone Number,Event Title,Number of Tickers \n';

    // Convert sorted event data to CSV rows
    const csvRows = filteredWinners.map(item =>
      `"${(item.fullNameData ?? 'n/a').toString().replace(/"/g, '""').replace(/\n/g, ' ')}",` +
      `"${(item.emailData ?? 'n/a').toString().replace(/"/g, '""').replace(/\n/g, ' ')}",` +
      `"${(item.companyData ?? 'n/a').toString().replace(/"/g, '""').replace(/\n/g, ' ')}",` +
      `"${(item.phoneNumberData ?? 'n/a').toString().replace(/"/g, '""').replace(/\n/g, ' ')}",` +
      `"${(item.title ?? 'n/a').toString().replace(/"/g, '""').replace(/\n/g, ' ')}",` +
      `"${(item.numOfTickets ?? 'n/a').toString().replace(/"/g, '""').replace(/\n/g, ' ')}"\n`
    ).join('');



    // Combine the header and rows
    const csvString = csvHeader + csvRows;

    // Create a Blob with CSV data
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Create a temporary link for downloading the CSV file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "winner.csv");

    // Append the link to the document, trigger the download, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - winners.length) : 0;

  const filteredWinners = applySortFilter(winners, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredWinners.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Winners Report | HelloVIP</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Winners Report
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar searchName={'Search event title...'} numSelected={selected.length} filterTitle={filterName} onFilterName={handleFilterByName} downloadReport={downloadWinnerCSV} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredWinners.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredWinners.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredWinners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, fullNameData, numOfTickets, emailData, companyData, phoneNumberData } = row;
                    const selectedUser = selected.indexOf(title) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, title)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {fullNameData || "N/A"}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row.emailData || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row.companyData || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row.phoneNumberData || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {title || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{numOfTickets || 'N/A'}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredWinners.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>


    </>
  );
}
